<template>
  <v-footer id="home-footer" color="secondary" dark min-height="80">
    <v-container fluid>
      <v-row>
        <v-col class="text-center py-0" cols="12">
          Copyright &copy; {{ new Date().getFullYear() }} -
          <a class="white--text" href="https://foodeo.es">Foodeo</a> 🧡
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  // data: () => ({
  //   social: [
  // {
  //   app: "LinkedIn",
  //   externalUrl: "https://test.com",
  //   to: null,
  // },
  // {
  //   app: "Twitter",
  //   externalUrl: "https://test.com",
  //   to: null,
  // },
  // {
  //   app: "Preguntas Frecuentes",
  //   externalUrl: null,
  //   to: "/preguntas-frecuentes",
  // },
  // {
  //   app: "Politicas",
  //   externalUrl: `${process.env.VUE_APP_API}/current-terms`,
  //   to: null,
  // },
  //   ],
  // }),
  // methods: {
  //   navigateTo(route) {
  //     if (route.externalUrl) {
  //       window.open(route.externalUrl, "_blank");
  //     } else {
  //       this.$router.push(route.to);
  //     }
  //   },
  // },
};
</script>

<style lang="sass">
#home-footer
  background-position: center bottom
  a
    text-decoration: none
</style>
